import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import CurveLine from "../../images/curveLine";
import TwitterIcon from "../../images/twitterIcon";
import GithubIcon from "../../images/githubIcon";
import LinkedInIcon from "../../images/linkedInIcon";
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

const TopSection = () => {
	const data = useStaticQuery(graphql`
		{
			site {
				siteMetadata {
					linkedinUrl
					githubUrl
					twitterUrl
				}
			}
		}
	`);
	return (
		<StyledTopSection>
			<div id="top-section-text">
				<h1>
					<span>Hey there, I'm Yordan Hristov.</span>
					<span>
						<br /> A <span className="highlight">web developer</span> based in
						Birmingham UK.
					</span>
				</h1>
				<div id="top-section-links">
					<a
						href={data.site.siteMetadata.linkedinUrl}
						target="_blank"
						rel="noreferrer"
					>
						<LinkedInIcon />
					</a>
					{/* <a
						href={data.site.siteMetadata.twitterUrl}
						target="_blank"
						rel="noreferrer"
					>
						<TwitterIcon />
					</a> */}
					<a
						href={data.site.siteMetadata.githubUrl}
						target="_blank"
						rel="noreferrer"
					>
						<GithubIcon />
					</a>
				</div>
			</div>
			<div id="picture-me">
				<motion.div
					initial={{ scale: 1.5 }}
					animate={{ scale: 1 }}
					transition={{ duration: 1, delay: 0.5 }}
				>
					<StaticImage src="../../images/me.jpg" alt="yordan" />
				</motion.div>
			</div>
			<CurveLine id="curve-line" />
		</StyledTopSection>
	);
};

const StyledTopSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: 1rem;
	width: 70%;
	height: 90vh;
	margin: auto;
	padding-bottom: 10rem;
	#top-section-text {
		width: 60%;
		h1,
		h2 {
			font-size: 3rem;
			font-weight: 300;
			span {
				.highlight {
					color: var(--accent);
					font-weight: 500;
				}
			}
		}
		#top-section-links {
			display: flex;
			column-gap: 1rem;
			margin-top: 3rem;
			svg {
				width: 50px;
				color: var(--fg);
				transition: color 150ms;
				&:hover {
					color: var(--accent);
				}
			}
		}
	}
	#picture-me {
		width: 400px;
		border-radius: 50%;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
		border: 2px solid var(--hoverBg);
		overflow: hidden;
	}
	#curve-line {
		position: absolute;
		width: 100%;
		z-index: -10;
		top: 35%;
		left: 0;
		stroke: var(--accent);
	}
	@media (max-width: 1400px) {
		#curve-line {
			top: 45%;
		}
	}
	@media (max-width: 1300px) {
		width: 90%;
		#curve-line {
			top: 50%;
		}
		#top-section-text {
			#top-section-links {
				margin-left: 25%;
			}
		}
	}
	@media (max-width: 1000px) {
		width: 90%;
		flex-direction: column-reverse;
		padding-bottom: 1rem;
		justify-content: center;
		#picture-me {
			width: 200px;
			margin-bottom: 3rem;
		}
		#curve-line {
			top: 25%;
		}
		@media (min-height: 700px) {
			#top-section-text {
				width: 80%;
				h1,
				h2 {
					font-size: 2.5rem;
					text-align: center;
				}
				#top-section-links {
					margin-left: 0;
					justify-content: center;
				}
			}
		}
		@media (max-height: 700px) {
			flex-direction: row;
			#curve-line {
				opacity: 0;
			}
			#top-section-text {
				width: 80%;
				h1,
				h2 {
					font-size: 2rem;
					text-align: center;
				}
				#top-section-links {
					margin-left: 0;
					justify-content: center;
				}
			}
		}
	}
	@media (max-width: 400px) {
		#top-section-text {
			h1,
			h2 {
				font-size: 2rem;
			}
		}
	}
`;

export default TopSection;
