import React from "react";
import styled from "styled-components";
import cvDownload from "../../files/Yordan Hristov - CV.pdf";

const AboutSection = () => {
	return (
		<StyledAboutSection>
			<h2>About me</h2>
			<div id="text-container">
				<p>
					My interest in web development started in 2012. I was running a game
					server and wanted to create a website for it to help expand it. Since
					I couldn't afford to pay someone to make me a website I decided to
					build one myself.
				</p>
				<p>
					I started learning about websites and how they were built. Since then
					I've learned back-end technologies like PHP, MySQL and Laravel.
					Recently I've focused a lot more on front-end. I've became really
					comfortable with HTML, CSS and Javascript and have also learned React
					and built a lot of projects with it.
				</p>
				<p>
					At the moment I'm looking into improving my back-end skills and also
					my UI & UX design skills. I also do photography during my free time.
				</p>
			</div>
			<div id="cv">
				<h3>Curriculum vitae</h3>
				<div>
					<a target="_blank" href={cvDownload} rel="noreferrer">
						View
					</a>
					<a href={cvDownload} download>
						Download
					</a>
				</div>
			</div>
		</StyledAboutSection>
	);
};

const StyledAboutSection = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 50%;
	margin: auto;
	margin-bottom: 1.1rem;
	h2 {
		position: relative;
		margin-bottom: 4rem;
		font-size: 2rem;
		&::before {
			content: "";
			height: 3px;
			width: 50%;
			background: var(--accent);
			position: absolute;
			bottom: -10px;
			border-radius: 5px;
		}
	}
	#text-container {
		position: relative;
		p {
			&:not(:last-child) {
				margin-bottom: 2rem;
			}
			font-size: 1.3rem;
		}
		&::before {
			content: "";
			height: 110%;
			width: 5px;
			background: var(--accent);
			position: absolute;
			left: -30px;
			top: -5%;
			border-radius: 5px;
		}
	}
	#cv {
		margin-top: 4rem;
		h3 {
			position: relative;
			font-size: 1.5rem;
			&::before {
				content: "";
				height: 3px;
				width: 50%;
				background: var(--accent);
				position: absolute;
				bottom: -10px;
				border-radius: 5px;
			}
		}
		div {
			margin-top: 1.5rem;
			display: flex;
			justify-content: space-around;
			align-items: center;
			gap: 1.5rem;
			a {
				color: var(--fg);
				text-decoration: none;
				font-weight: bold;
				position: relative;
				font-size: 1.1rem;
				transition: color 150ms;
				&:hover {
					color: var(--accent);
				}
			}
		}
	}
	@media (max-width: 1300px) {
		width: 70%;
	}
	@media (max-width: 400px) {
		width: 80%;
		h2 {
			font-size: 1.6rem;
			margin-bottom: 3rem;
		}
		#text-container {
			p {
				font-size: 1.2rem;
			}
			&::before {
				content: none;
			}
		}
	}
`;

export default AboutSection;
