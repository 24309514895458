import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import HomePageFeaturedProject from "../projects/HomePageFeaturedProject";

const ProjectsSection = () => {
	const data = useStaticQuery(graphql`
		{
			allMarkdownRemark(
				filter: {
					frontmatter: { featured: { eq: true } }
					fields: { type: { eq: "project" } }
				}
				sort: { fields: frontmatter___title, order: ASC }
			) {
				edges {
					node {
						frontmatter {
							title
							desc
							image {
								childImageSharp {
									gatsbyImageData(placeholder: DOMINANT_COLOR)
								}
							}
						}
						id
						fields {
							slug
						}
					}
				}
			}
		}
	`);
	return (
		<StyledProjectsSection>
			<h2>My projects</h2>
			<div id="projects-container">
				{data.allMarkdownRemark.edges.map((project) => (
					<HomePageFeaturedProject key={project.node.id} project={project} />
				))}
			</div>
		</StyledProjectsSection>
	);
};

const StyledProjectsSection = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 70%;
	margin: auto;
	min-height: 100vh;
	h2 {
		position: relative;
		margin-bottom: 4rem;
		font-size: 2rem;
		&::before {
			content: "";
			height: 3px;
			width: 50%;
			background: var(--accent);
			position: absolute;
			bottom: -10px;
			border-radius: 5px;
		}
	}
	#projects-container {
		display: flex;
		column-gap: 2rem;
	}
	@media (max-width: 1300px) {
		width: 80%;
	}
	@media (max-width: 1000px) {
		margin-top: 5rem;
		#projects-container {
			flex-direction: column;
			row-gap: 4rem;
		}
	}
	@media (max-width: 400px) {
		h2 {
			font-size: 1.6rem;
			margin-bottom: 3rem;
		}
	}
`;

export default ProjectsSection;
