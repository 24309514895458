import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";

const HomePageFeaturedProject = ({ project }) => {
	return (
		<StyledHomePageFeaturedProjectCard>
			<Link to={project.node.fields.slug} state={{ from: "/" }}>
				<div className="img-wrapper">
					<GatsbyImage
						image={getImage(project.node.frontmatter.image)}
						alt={project.node.frontmatter.title}
					/>
				</div>
				<h3>{project.node.frontmatter.title}</h3>
				<p>{project.node.frontmatter.desc}</p>
				<p className="view-details-text">View Details</p>
			</Link>
		</StyledHomePageFeaturedProjectCard>
	);
};

const StyledHomePageFeaturedProjectCard = styled.div`
	a {
		color: var(--fg);
		text-decoration: none;
		font-weight: bold;
		position: relative;
		.img-wrapper {
			margin-bottom: 0.3rem;
			border-bottom-left-radius: 0.5rem;
			border-bottom-right-radius: 0.5rem;
			overflow: hidden;
			transition: border-radius 150ms;
			border-radius: 1rem;
			img {
				transition: all 150ms;
			}
		}
		h3 {
			position: relative;
			margin-top: 1.2rem;
			margin-bottom: 1.6rem;
			font-size: 1.5rem;
			&::before {
				content: "";
				height: 3px;
				width: 10%;
				background: var(--accent);
				position: absolute;
				bottom: -10px;
				border-radius: 5px;
			}
		}
		p {
			font-weight: normal;
			font-size: 1.2rem;
			margin-bottom: 1.2rem;
		}
		.view-details-text {
			display: inline;
			font-weight: bold;
			font-size: 1rem;
			position: relative;
			&::before {
				content: "";
				width: 0;
				height: 3px;
				right: 0;
				bottom: -5px;
				background: var(--accent);
				position: absolute;
				border-radius: 5px;
				transition: 300ms;
			}
		}
		&:hover {
			.img-wrapper {
				img {
					transform: scale(1.05);
				}
			}
			.view-details-text {
				&::before {
					left: 0;
					width: 100%;
				}
			}
		}
	}

	// .image-link {
	// 	&:hover {
	// 		~ a {
	// 			::before {
	// 				left: 0;
	// 				width: 100%;
	// 			}
	// 		}
	// 	}
	// }
	// .project-image {
	// 	border-radius: 10px;
	// 	overflow: hidden;
	// 	cursor: pointer;
	// }
	// img {
	// 	width: 100%;
	// 	transition: all 150ms;
	// 	&:hover {
	// 		transform: scale(1.05);
	// 	}
	// }
	// h3 {
	// 	position: relative;
	// 	margin-top: 1.2rem;
	// 	margin-bottom: 1.6rem;
	// 	font-size: 1.3rem;
	// 	&::before {
	// 		content: "";
	// 		height: 3px;
	// 		width: 10%;
	// 		background: var(--accent);
	// 		position: absolute;
	// 		bottom: -10px;
	// 		border-radius: 5px;
	// 	}
	// }
	// p {
	// 	font-size: 1.2rem;
	// 	margin-bottom: 1.2rem;
	// }
	// a {
	// 	color: var(--fg);
	// 	text-decoration: none;
	// 	font-weight: bold;
	// 	position: relative;
	// 	&::before {
	// 		content: "";
	// 		width: 0;
	// 		height: 3px;
	// 		right: 0;
	// 		bottom: -5px;
	// 		background: var(--accent);
	// 		position: absolute;
	// 		border-radius: 5px;
	// 		transition: 300ms;
	// 	}
	// 	&:hover::before {
	// 		left: 0;
	// 		width: 100%;
	// 	}
	// }
`;

export default HomePageFeaturedProject;
