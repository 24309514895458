import * as React from "react";
import Layout from "../components/Layout";
import TopSection from "../components/portfolio/TopSection";
import AboutSection from "../components/portfolio/AboutSection";
import ProjectsSection from "../components/portfolio/ProjectsSection";
import ContactSection from "../components/portfolio/ContactSection";

const HomePage = () => {
	return (
		<Layout>
			<TopSection />
			<AboutSection />
			<ProjectsSection />
			<ContactSection />
		</Layout>
	);
};

export default HomePage;
