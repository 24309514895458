import React from "react";
import styled from "styled-components";

const ContactSection = () => {
	return (
		<StyledContactSection>
			<h2>Get in touch</h2>
			<div id="contact-form-container">
				<form
					name="contact"
					method="post"
					data-netlify="true"
					data-netlify-honeypot="bot-field"
				>
					<input type="hidden" name="form-name" value="contact" />
					<div>
						<label htmlFor="name">Name</label>
						<input
							id="name"
							name="name"
							type="text"
							placeholder="Enter your name"
							required
						/>
					</div>
					<div>
						<label htmlFor="email">Email</label>
						<input
							id="email"
							name="email"
							type="email"
							placeholder="Enter your email address"
							required
						/>
					</div>
					<div>
						<label htmlFor="message">Message</label>
						<textarea
							id="message"
							name="message"
							placeholder="Enter your message"
							rows="8"
							required
						></textarea>
					</div>
					<div>
						<button>Submit</button>
					</div>
				</form>
			</div>
		</StyledContactSection>
	);
};

const StyledContactSection = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 40%;
	margin: auto;
	min-height: 100vh;
	h2 {
		position: relative;
		margin-bottom: 4rem;
		font-size: 2rem;
		&::before {
			content: "";
			height: 3px;
			width: 50%;
			background: var(--accent);
			position: absolute;
			bottom: -10px;
			border-radius: 5px;
		}
	}
	#contact-form-container {
		width: 100%;
		form {
			div {
				display: flex;
				flex-direction: column;
				&:not(:first-child) {
					margin-top: 1rem;
				}
				&:nth-child(3) {
					margin-bottom: 2rem;
				}
				&:last-child {
					margin-bottom: 2rem;
				}
				label {
					font-size: 1.4rem;
					margin-bottom: 0.4rem;
				}
				input,
				textarea {
					width: 100%;
					font-size: 1.4rem;
					padding: 0.6rem;
					background: var(--formBg);
					border: none;
					border-bottom: 1px solid var(--accent);
					outline: none;
					margin-bottom: 2px;
					caret-color: var(--accent);
					font-family: Rubik, sans-serif;
					color: var(--fg);
					::placeholder {
						color: var(--formPlaceholder);
						font-weight: 300;
					}
					&:focus {
						border-bottom: 3px solid var(--accent);
						margin-bottom: 0px;
					}
				}
				textarea {
					resize: vertical;
				}
			}
			button {
				font-size: 1.4rem;
				padding: 0.6rem 2rem;
				background: var(--formBg);
				border: none;
				border-bottom: 1px solid var(--accent);
				cursor: pointer;
				display: block;
				margin: auto;
				font-family: Rubik, sans-serif;
				margin-bottom: 2px;
				color: var(--fg);
				&:hover {
					border-bottom: 3px solid var(--accent);
					margin-bottom: 0px;
				}
			}
		}
	}
	@media (max-width: 1300px) {
		width: 60%;
	}
	@media (max-width: 1000px) {
		margin-top: 5rem;
	}
	@media (max-width: 800px) {
		width: 80%;
	}
	@media (max-width: 600px) {
		#contact-form-container {
			form {
				button {
					width: 100%;
				}
			}
		}
	}
	@media (max-width: 400px) {
		h2 {
			font-size: 1.6rem;
			margin-bottom: 3rem;
		}
	}
`;

export default ContactSection;
