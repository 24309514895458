import React from "react";
import { motion } from "framer-motion";

const CurveLine = ({ id }) => (
	<motion.svg
		preserveAspectRatio="none"
		viewBox="0 0 1920 444"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		id={id}
	>
		<motion.path
			initial={{ pathLength: 0, opacity: 0 }}
			animate={{ pathLength: 1, opacity: 1 }}
			transition={{ duration: 2 }}
			d="M-137 2C-105.833 135.833 28.3 409.9 315.5 435.5C674.5 467.5 891 214 1193 187C1434.6 165.4 1828 343.667 1994.5 435.5"
			strokeWidth="10"
		/>
	</motion.svg>
);

export default CurveLine;
